import firebase from 'firebase/app';
import 'firebase/firestore'
import 'firebase/auth'

const config = {
  apiKey: "AIzaSyBKb14N0bfD1QWQXOpZ6ccJwAYi2Ge9BLw",
  authDomain: "jobby-4c1bf.firebaseapp.com",
  databaseURL: "https://jobby-4c1bf.firebaseio.com",
  projectId: "jobby-4c1bf",
  storageBucket: "jobby-4c1bf.appspot.com",
  messagingSenderId: "744476156541",
  appId: "1:744476156541:web:de796d81e5d0173af40714",
  measurementId: "G-PMCWQEE6JZ"
}

firebase.initializeApp(config);
export default firebase;