import React from 'react';
import firebase from './firebase_jobby';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';



import './App.css';

class App extends React.Component {

  db = firebase.firestore();
  state = { jobs: [] };
  collectionRef = this.db.collection('jobs');

  getJobs = () => {
    this.collectionRef.get().then(
      (querySnapshot) => {
        const jobs = [];
        querySnapshot.forEach((doc) => {
          jobs.push({ id: doc.id, ...doc.data(), ref: doc.ref });
        });
        this.setState({ jobs: jobs });
      });
  }

  setDone = (ref) => {
    ref.update({ lastDone: (new Date(Date.now())) });
  }

  addJob = (newJob) => {
    this.collectionRef.add(newJob);
  }

  // Load data to begin with and refresh if data changes
  componentDidMount = () => {
    this.collectionRef.onSnapshot(() => {
      this.getJobs()
    });
  }

  render() {
    return (<Container>
      <Row>
        <Col>
          <div className="App" >
            <header className="App-header">
              <h2>Jobby</h2>
            </header>
            <SignInScreen />
            <JobTable
              jobs={this.state.jobs}
              setDone={this.setDone}
            />
            <NewJobForm addJob={this.addJob} />
          </div>
        </Col>
      </Row>
    </Container>
    );
  }
}

class JobTable extends React.Component {
  state = {
    showModal: false,
    modalJob: null
  };
  renderLastDone = (date) => {
    if (!date) {
      return "-";
    }
    else {
      return date.toLocaleDateString(undefined, {});
    }
  }

  renderDaysOverdue = (lastDone, frequency) => {
    const MS_IN_DAY = 24 * 60 * 60 * 1000;
    if (!lastDone) {
      return <td>-</td>;
    }
    let dueDate = new Date(lastDone.getTime() + frequency * 7 * MS_IN_DAY);
    let overdueByDays = Math.floor((new Date(Date.now()) - dueDate) / MS_IN_DAY);

    if (overdueByDays > 0) { return <td className='overdue'>Overdue by {overdueByDays} days</td> }
    return <td>Due in {-overdueByDays} days</td>;
  }

  renderDoneNowButton = (ref) => {
    return <button onClick={() => {
      this.props.setDone(ref, new Date(Date.now()))
    }
    }>Done Now</button>
  }

  showModal = (job) => {
    console.log(job)
    this.setState({
      showModal: true,
      modalJob: job
    }
    );
  }
  closeModal = () => {
    this.setState({ showModal: false })
  }

  getJobTitle = (job) => { if (job) { return job.name } else { return 'No job' } }
  render() {
    return <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Every x weeks</th>
            <th>Last done</th>
            <th>Overdue</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.props.jobs.map((job) => {
            let lastDone = job.lastDone ? job.lastDone.toDate() : null;
            return (
              <tr key={job.id} onClick={() => { this.showModal(job) }}>
                <td>{job.name}</td>
                <td>{job.frequency}</td>
                <td>{this.renderLastDone(lastDone)}</td>
                {this.renderDaysOverdue(lastDone, job.frequency)}
                <td>{this.renderDoneNowButton(job.ref)}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      <Modal show={this.state.showModal} onHide={this.closeModal}>
        <Modal.Body>{this.getJobTitle(this.state.modalJob)}</Modal.Body>
      </Modal>
    </div>
  }
}

class NewJobForm extends React.Component {

  constructor(props) {
    super(props);
    this.nameInput = React.createRef();
    this.frequencyInput = React.createRef();
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let newJob = {
      name: this.nameInput.current.value,
      frequency: parseInt(this.frequencyInput.current.value),
      lastDone: null,
    };
    this.props.addJob(newJob);
  }

  render() {
    return (
      <form
        className='formContainer'
        onSubmit={this.handleSubmit}
      >
        <div className='inputContainer'>
          Name
      <input
            className='textInput'
            type='text'
            ref={this.nameInput}
          />
        </div>
        <div className='inputContainer'>
          How often?
      <input
            className='textInput'
            type='number'
            min={1}
            step={1}
            ref={this.frequencyInput}
          />
        </div>
        <div className='inputContainer'>
          <input
            type='submit'
            value='Add'
          />
        </div>
      </form>
    );
  }
}


class SignInScreen extends React.Component {

  // The component's Local state.
  state = {
    isSignedIn: false // Local signed-in state.
  };

  // Configure FirebaseUI.
  uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false
    }
  };

  // Listen to the Firebase Auth state and set the local state.
  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (user) => this.setState({ isSignedIn: !!user })
    );
  }

  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    if (!this.state.isSignedIn) {
      return (
        <div>
          <h1>My App</h1>
          <p>Please sign-in:</p>
          <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()} />
        </div>
      );
    }
    return (
      <div>
        <p>Welcome {firebase.auth().currentUser.displayName}! You are now signed-in!</p>
        <a onClick={() => firebase.auth().signOut()}>Sign-out</a>
      </div>
    );
  }
}



export default App;
